.header {
  padding-top: 200px;
  padding-bottom: 80px;
  @media (max-width: 1024px) {
    padding-top: 150px;
    padding-bottom: 50px; }
  .logo {
    text-align: center; } }



