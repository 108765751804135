#main {
 .section-text {
   margin-top: 60px;
   margin-bottom: 160px;
   @media (max-width: 1024px) {
     margin-top: 50px;
     margin-bottom: 100px; }
   .main-text {
     max-width: 1140px;
     margin: 0 auto;
     @media (max-width: 1280px) {
       padding:  0 30px;
       width: 100%;
       box-sizing: border-box; }


     .mini-hr {
       width: 60px;
       height: 2px;
       background-color: $brown;
       margin: 0 auto 30px auto;
       @media (max-width: 1024px) {
         margin: 0 auto 25px auto; } }
     p {
       font-size: 16px;
       line-height: 1.5;
       text-align: center;
       letter-spacing: 0.07em; } } } }
.section-contact {
  margin-bottom: 160px;
  @media (max-width: 1024px) {
    margin-bottom: 100px; }
  @media (max-width: 768px) {
    margin-bottom: 50px; }
  .contact-list {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    .contact-item {
      padding:  0 32px;
      box-sizing: border-box;
      @media (max-width: 768px) {
        text-align: center;
        flex: 0 0 100%;
        margin-bottom: 50px; }

      .mobile-letter {
        margin-bottom: 20px;
        color: $brown;
        @media (min-width: 769px) {
          display: none; } }
      .dsk-letter {
        font-size: 16px;
        color: $brown;
        @media (max-width: 768px) {
          display: none; } }
      p {
        color: #4B4B4B;
        font-size: 16px;
        line-height: 1.2;
        a {
          color: $white;
          text-decoration: none;
          @include transition(0.3);
          &:hover {
            opacity: 0.8; } } } } } }
.section-project {
  padding:  0 35px 175px 35px;
  .project-title {
    text-align: center;
    margin-bottom: 55px;

    p {
      font-weight: bold;
      font-size: 24px;
      text-align: center;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: $brown;
      margin-bottom: 40px; }
    svg {
      display: block;
      margin: 0 auto; } }
  .project-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 -35px;
    transition: max-height 4s linear;
    position: relative;
    @media (max-width: 425px) {
      max-height: 280px;
      //height: 301px
      overflow: hidden;

      &.open {
        max-height: 4000px;
        height: auto; } }
    .bullet {
      color: rgba(255, 255, 255, 0.5);
      @media (max-width: 768px) {
        font-size: 12px; }
      @media (max-width: 425px) {
        display: none; } }
    .project-item {
      position: relative;
      @media (max-width: 425px) {
        flex: 0 0 100%;
        text-align: center; }
      p {
        padding: 25px 35px;
        color: rgba(255, 255, 255, 0.5);
        font-size: 14px;
        line-height: 1.2;
        display: block;
        text-decoration: none;
        @include transition(0.3);
        @media (max-width: 1024px) {
          font-size: 13px;
          padding: 25px 25px; }
        @media (max-width: 768px) {
          font-size: 12px;
          padding: 17px 25px; }
        &:hover {
          color: $brown; } } } }
  .mobile-open-full {
    margin-top: 30px;
    @media (min-width: 426px) {
      display: none; }
    .open {
      width: 169px;
      display: block;
      background: transparent;
      border: none;
      margin: 0 auto;
      -webkit-tap-highlight-color: transparent;
      &:hover {
        opacity: 0.5;
        background: transparent; }
      &:active {
        background: transparent; }


      span {
        display: block;
        margin-bottom: 25px;
        font-weight: bold;
        color: #BFA599; }
      svg {
        display: block;
        margin: 0 auto; } } } }
.up-button {
  display: none;
  margin-top: 50px;
  @media (min-width: 426px) {
    display: block;
    text-align: center; }
  &.open {
    display: block; }
  a {
    text-transform: uppercase;
    font-size: 25px;
    align-items: center;
    background: transparent;
    border: none;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
    display: block;
    width: 50px;
    margin: 0 auto; }

  span {
    color: $brown; }


  svg {
    margin-left: 10px;
    transform: rotate(180deg); } }






