$black: #1B191D;
$brown: #BFA599;
$white: #FFFFFF;

@mixin transition($time) {
  transition: all $time+s linear; }

body {
  font-family: 'Arial', sans-serif;
  background-color: $black;
  font-weight: 400;
  color: $white;
  line-height: 1.4; }
.bold {
  font-weight: bold; }
.p-center {
  text-align: center; }
.ls-0-1 {
  letter-spacing: 0.1px; }
a:hover {
  color: #7873f5; }
input, button {
  &:focus {
    outline: none; } }
.pointer {
  cursor: pointer; }
.container {
  width: 100%;
  max-width:   1438px;
  margin: 0 auto; }


